import "react-app-polyfill/stable";
import "react-loading-skeleton/dist/skeleton.css";

import { BrowserTracing, init } from "@sentry/react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga4";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import reportWebVitals from "./utils/reportWebVitals";

ReactGA.initialize("G-6QTDNRTET1");
ReactGA.send("pageview");

if (process.env.REACT_APP_ENV === "prod") {
  init({
    dsn: "https://241c7dbc555f46e78213910d10d046eb@o4505010257788928.ingest.sentry.io/4505010272796672",
    integrations: [new BrowserTracing()],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  });
}

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
