import React from "react";
import { Spinner } from "react-bootstrap";

const Loading = () => (
  <div className="h-100 d-flex align-items-center justify-content-center">
    <Spinner
      animation="border"
      role="loading"
      variant="primary"
      style={{ width: "4rem", height: "4rem" }}
    >
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  </div>
);

export default Loading;
