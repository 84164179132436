import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface IFile {
  name?: string;
  mime?: string;
  url: string;
  expires: number;
}

export interface IFilesState {
  items: IFile[];
}

const initialState: IFilesState = {
  items: [],
};

export const filesSlice = createSlice({
  name: "files",
  initialState,
  reducers: {
    addFile: (state, action: PayloadAction<IFile>) => {
      state.items.push(action.payload);
    },
    removeFile: (state, action: PayloadAction<IFile>) => {
      state.items = state.items.filter(
        (file) => file.url !== action.payload.url
      );
    },
  },
});

export const { addFile, removeFile } = filesSlice.actions;

export const selectFiles = (state: RootState) => state.files.items;

export const getFile = (state: RootState, url: string) =>
  state.files.items.find((file) => file.url === url);

export default filesSlice.reducer;
