import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from "react";

import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession,
} from "amazon-cognito-identity-js";

import {
  ActionMap,
  AuthState,
  AuthUser,
  CognitoContextType,
} from "../types/auth";

import axios from "../utils/axios";
import { cognitoConfig } from "../config";

const INITIALIZE = "INITIALIZE";
const SIGN_OUT = "SIGN_OUT";

const UserPool = new CognitoUserPool({
  UserPoolId: cognitoConfig.userPoolId || "",
  ClientId: cognitoConfig.clientId || "",
});

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  groups: [],
};

type AuthActionTypes = {
  [INITIALIZE]: {
    isAuthenticated: boolean;
    user: AuthUser;
    groups?: string[];
  };
  [SIGN_OUT]: undefined;
};

type CognitoActions =
  ActionMap<AuthActionTypes>[keyof ActionMap<AuthActionTypes>];

const reducer = (state: AuthState, action: CognitoActions) => {
  if (action.type === INITIALIZE) {
    const { isAuthenticated, user, groups } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      groups,
    };
  }
  if (action.type === SIGN_OUT) {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }
  return state;
};

const AuthContext = createContext<CognitoContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isFirstRun, setIsFirstRun] = useState(true);

  const getUserAttributes = useCallback(
    (currentUser: CognitoUser): Record<string, any> =>
      new Promise((resolve, reject) => {
        currentUser.getUserAttributes((err, attributes) => {
          if (err) {
            reject(err);
          } else {
            const results: Record<string, any> = {};
            attributes?.forEach((attribute) => {
              results[attribute.Name] = attribute.Value;
            });
            resolve(results);
          }
        });
      }),
    []
  );

  const getSession = useCallback(
    () =>
      new Promise((resolve, reject) => {
        const user = UserPool.getCurrentUser();
        if (user) {
          user.getSession(
            async (err: Error | null, session: CognitoUserSession | null) => {
              if (err) {
                console.log(err);
                reject(err);
              } else {
                const attributes = await getUserAttributes(user);

                // generate a tokens
                const accessToken = session?.getAccessToken().getJwtToken();
                const idToken = session?.getIdToken().getJwtToken();
                const refreshToken = session?.getRefreshToken().getToken();

                const token = {
                  accessToken,
                  idToken,
                  refreshToken,
                };

                // added groups to payload
                const groups = session?.getIdToken().payload["cognito:groups"];

                // added token to axios header
                if (idToken) {
                  axios.defaults.headers.common["Authorization"] = idToken;
                }

                dispatch({
                  type: INITIALIZE,
                  payload: {
                    isAuthenticated: true,
                    user: {
                      ...attributes,
                      ...token,
                    },
                    groups: groups,
                  },
                });

                resolve({
                  user,
                  groups,
                  session,
                  token: token,
                  headers: { Authorization: idToken },
                });
              }
            }
          );
        } else {
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      }),
    [getUserAttributes]
  );

  const initialize = useCallback(async () => {
    try {
      await getSession();
    } catch {
      console.log("Error initializing");

      dispatch({
        type: INITIALIZE,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, [getSession]);

  useEffect(() => {
    initialize();
    setIsFirstRun(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      initialize();
    }, 1000 * 60 * 60 * 24); // 24 hours

    return () => clearInterval(interval);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirstRun]);

  const signIn = useCallback(
    (email, password) =>
      new Promise((resolve, reject) => {
        const user = new CognitoUser({
          Username: email,
          Pool: UserPool,
        });

        const authDetails = new AuthenticationDetails({
          Username: email,
          Password: password,
        });

        user.authenticateUser(authDetails, {
          onSuccess: (data) => {
            getSession();
            resolve(data);
          },
          onFailure: (err) => {
            reject(err);
          },
          newPasswordRequired: (userAttributes, requiredAttributes) => {
            // delete email verified
            delete userAttributes.email_verified;

            resolve({
              message: "FORCE_CHANGE_PASSWORD",
              userAttributes,
              requiredAttributes,
            });
          },
        });
      }),
    [getSession]
  );

  const signOut = () => {
    const user = UserPool.getCurrentUser();
    if (user) {
      user.signOut();
      dispatch({ type: SIGN_OUT });
    }
  };

  const signUp = (
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ) =>
    new Promise((resolve, reject) => {
      UserPool.signUp(
        email,
        password,
        [
          new CognitoUserAttribute({ Name: "email", Value: email }),
          new CognitoUserAttribute({
            Name: "name",
            Value: `${firstName} ${lastName}`,
          }),
        ],
        [],
        async (err) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(undefined);
          // Set destination URL here
          window.location.href = "";
        }
      );
    });

  const resetPassword = (email: string) =>
    new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username: email,
        Pool: UserPool,
      });

      user.forgotPassword({
        onSuccess: (data) => {
          resolve(data);
        },
        onFailure: (err) => {
          reject(err);
        },
      });
    });

  const resetPasswordConfirm = (
    email: string,
    code: string,
    newPassword: string
  ) =>
    new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username: email,
        Pool: UserPool,
      });

      if (user) {
        user.confirmPassword(code, newPassword, {
          onSuccess: (data) => {
            signIn(email, newPassword).then();
            resolve(data);
          },
          onFailure: (err) => {
            reject(err);
          },
        });
      }
    });

  const changePassword = (
    email: string,
    oldPassword: string,
    newPassword: string
  ) =>
    new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username: email,
        Pool: UserPool,
      });

      if (user) {
        user.changePassword(oldPassword, newPassword, (err, result) => {
          if (err) {
            reject(err);
          } else {
            signIn(email, newPassword).then();
            resolve(result);
          }
        });
      }
    });

  const completeNewPasswordChallenge = (
    email: string,
    oldPassword: string,
    newPassword: string
  ) =>
    new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username: email,
        Pool: UserPool,
      });

      const authDetails = new AuthenticationDetails({
        Username: email,
        Password: oldPassword,
      });

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          getSession();
          resolve(data);
        },
        onFailure: (err) => {
          reject(err);
        },
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          user.completeNewPasswordChallenge(newPassword, null, {
            onSuccess: (data) => {
              getSession();
              resolve(data);
            },
            onFailure: (err) => {
              reject(err);
            },
          });
        },
      });
    });

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "cognito",
        user: {
          displayName: state?.user?.name || "Undefined",
          role: state?.groups || [],
          ...state.user,
        },
        signIn,
        signUp,
        signOut,
        resetPassword,
        resetPasswordConfirm,
        changePassword,
        completeNewPasswordChallenge,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
