import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      "Welcome back": "Welcome back",
      actions: "Actions",
      cancel: "Cancel",
      create: "Create",
      createdAt: "Created At",
      delete: "Delete",
      deleteCancel: "Cancel Delete {{ data }}!",
      deleteConfirmation: "Are you sure you want to delete {{ data }}?",
      deleteProgress: "Deleting {{ data }} in progress...",
      deleteSuccess: "Delete {{ data }} successfully!",
      edit: "Edit",
      email: "Email",
      id: "ID",
      iswhatsapp: "Registered on Whatsapp",
      lastcheck: "Last Check",
      message_select: "Select a message to start a conversation",
      name: "Name",
      notregistered: "Not Registered",
      phone: "Phone",
      registered: "Registered",
      save: "Save",
      Search: "Search projects…",
      status: "Status",
      unknown: "Unknown",
      updatedAt: "Updated At",
      next: "Next",
      previous: "Previous",
      firstPage: "First Page",
      openinfull: "Click to open in full screen",
      loadmore: "Load More",
      slug: "Slug",
      category: "Category",
      description: "Description",
      hash: "Hash",
      size: "Size",
      type: "Type",
      drive: "Drive",
      addInfo: "Added Data {{ data }}",
      addSuccess: "Add Data {{ data }} Successfully",
      addError: "Add Data {{ data }} Failed",
      errMessage: "Error {{ data }}",
      needHelp: "Need Help?",
      helpMessage:
        "Contact our support team if you need help with your account.",
      helpSend: "Hi Admin {{ data }}, I need help with my account. Thanks",
      live: "Live",
      or: "Or",
      dashboard: {
        dragNDrop: "Drag 'n' drop some files here, or click to select files",
        fileMax:
          "{{ data }} files are the maximum number of files you can drop here",
      },
      unlockFile: {
        confirm:
          "Would you like to unlock this file {{ name }}? You will be charged {{ data }} bandwidth quota",
        info: "Unlock File with Your Bandwidth Quota, Size {{ data }}",
        success: "Successfully unlocked file {{ data }}",
        error:
          "Failed to unlock file {{ data }}. Error: {{ error }}, Please try again later",
      },
    },
  },
  id: {
    translation: {
      "Welcome back": "Selamat datang kembali",
      actions: "Aksi",
      cancel: "Batal",
      create: "Buat",
      createdAt: "Dibuat pada",
      delete: "Hapus",
      deleteCancel: "Batal menghapus {{ data }}!",
      deleteConfirmation: "Apakah Anda yakin ingin menghapus {{ data }}?",
      deleteProgress: "Menghapus {{ data }} dalam proses...",
      deleteSuccess: "Berhasil menghapus {{ data }}!",
      edit: "Ubah",
      email: "Email",
      id: "ID",
      iswhatsapp: "Terdaftar di Whatsapp",
      lastcheck: "Terakhir dicek",
      message_select: "Pilih pesan untuk memulai percakapan",
      name: "Nama",
      notregistered: "Tidak Terdaftar",
      phone: "Telepon",
      registered: "Terdaftar",
      save: "Simpan",
      Search: "Cari proyek…",
      status: "Status",
      unknown: "Tidak diketahui",
      updatedAt: "Diperbarui pada",
      next: "Selanjutnya",
      previous: "Sebelumnya",
      firstPage: "Halaman Pertama",
      openinfull: "Klik untuk membuka dalam layar penuh",
      loadmore: "Muat Lebih Banyak",
      slug: "Slug",
      category: "Kategori",
      description: "Deskripsi",
      hash: "Hash",
      size: "Ukuran",
      type: "Tipe",
      drive: "Drive",
      addInfo: "Menambahkan Data {{ data }}",
      addSuccess: "Berhasil menambahkan Data {{ data }}",
      addError: "Gagal menambahkan Data {{ data }}",
      errMessage: "Error {{ data }}",
      needHelp: "Butuh Bantuan?",
      helpMessage: "Hubungi tim dukungan kami jika Anda membutuhkan bantuan.",
      helpSend:
        "Hai Admin {{ data }}, saya butuh bantuan dengan akun saya. Terima kasih",
      live: "Live",
      or: "Atau",
      dashboard: {
        dragNDrop:
          "Seret dan lepaskan beberapa file di sini, atau klik untuk memilih file",
        fileMax:
          "{{ data }} file adalah jumlah maksimum file yang dapat Anda seret dan lepaskan di sini",
      },
      unlockFile: {
        info: "Buka Kunci File dengan Kuota Bandwidth Anda, Ukuran {{ data }}",
        success: "Berhasil membuka kunci file {{ data }}",
        error:
          "Gagal membuka kunci file {{ data }}. Error: {{ error }}, Silakan coba lagi nanti",
      },
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "id",
  fallbackLng: "id",
  interpolation: {
    escapeValue: false,
  },
});
